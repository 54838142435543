import localStorageService from '@/services/local/LocalStorageService';
import { IS_NEW_API } from '@/helpers/const';
import Ajax from '../../helpers/ajax';
import { EventBusService } from './EventBusService';

const inBrowser = typeof window !== 'undefined';

function ga_setAccount(account_id: number) {
	if (inBrowser) {
		if (account_id) {
			window.account_id = account_id;
		} else {
			delete window.account_id;
		}
	}
}

class UserService extends EventBusService {
	private _currentUser: {
		is_logged_in: boolean;
		is_impersonate: boolean;
		user?: Record<string, any>;
		account?: Record<string, any>;
		real?: Record<string, any>;
		manager_info?: Record<string, any>;
	} = { is_logged_in: false, is_impersonate: false }; // todo: описать типы

	constructor() {
		super();
		this.on('Ajax::unauthorized', async () => {
			if (this._currentUser && this._currentUser.is_logged_in) {
				this._currentUser.is_logged_in = false;
				await this.loadCurrentUser();
				if (!this.isLoggedIn()) {
					window.router.replace('/');
				}
			}
		});
		this.on('Ajax::updateBalance', async (balance) => {
			await this.updateBalance(balance);
		});
	}

	get currentUser() {
		return this._currentUser;
	}

	async loadCurrentUser(query: string | null = null) {
		let url = 'whoami';

		if (query) {
			url += `?${query}`;
		}

		const resp = await Ajax.get(url);

		if (resp.response) {
			this._currentUser = resp.response;
			if (this._currentUser && this._currentUser.user /* && !this.currentUser.is_impersonate */) {
				ga_setAccount(this._currentUser.user.account_id);
			}
			if (typeof resp.balance_current !== 'undefined') {
				this.updateBalance(resp.balance_current);
			}
		} else {
			this._currentUser = { is_logged_in: false, is_impersonate: false };
			// Специально отключаю сброс GA-юзера при логауте
			//ga_setAccount(0);
		}

		await this.emit('change', [this._currentUser.is_logged_in]);
	}

	isLoggedIn() {
		return this._currentUser && this._currentUser.is_logged_in;
	}

	isImpersonate() {
		return this._currentUser && this._currentUser.is_impersonate;
	}

	get isCPAOptimizationAllowed() {
		if (!this.isLoggedIn() || !this._currentUser.account) {
			return false;
		}

		return !!this._currentUser.account.is_cpa_optimization_allowed;
	}

	isFeatureEnabled(feature) {
		if (
			typeof feature !== 'string' ||
			!this.isLoggedIn() ||
			!this._currentUser.account ||
			!this._currentUser.account.account_feature
		) {
			return false;
		}

		const enabledFeatures = this._currentUser.account.account_feature;

		if (!Array.isArray(enabledFeatures)) {
			return false;
		}

		return enabledFeatures.indexOf(feature) !== -1;
	}

	getHomepage() {
		if (this.isLoggedIn()) {
			const user = this._currentUser.user;

			switch (user?.role_type) {
				case 'adm':
					if (user.rules && user.role_homepage) {
						return user.role_homepage;
					}

					return '/adm/advertisers';
				case 'adv':
					return '/adv/campaigns';
				case 'pub':
					if (user.role_alias === 'pub-lite') {
						return '/pub/statistics/messages';
					}

					return '/pub/statistics';

				case 'aff-pub':
				case 'aff-adv':
					return '/unavailable';
			}

			return '/';
		}

		return '/auth/login';
	}

	async impersonate(id, type = 'user_id') {
		if (type === 'user_id') {
			await Ajax.get(`/auth/impersonate?user_id=${id}`);
		} else {
			await Ajax.get(`/auth/impersonate?acc_id=${id}`);
		}

		await this.loadCurrentUser();

		localStorageService.set('from-page-impersonate', window.router.currentRoute.value.fullPath);

		await this.emit('refresh', [this._currentUser.is_logged_in]);
	}

	async login(user_login, user_password, recaptcha = '') {
		const resp = await Ajax.post('auth/login', { user_login, user_password, 'g-recaptcha-response': recaptcha });

		if (resp.error) {
			return resp.error;
		}

		if ((IS_NEW_API && resp.response?.status === 'success') || (!IS_NEW_API && resp.response)) {
			await this.loadCurrentUser();
			await this.emit('refresh', [true]);

			return true;
		} else if (IS_NEW_API && resp.response?.status === 'verification') {
			return { code: 777 };
		}
	}

	async signup(data) {
		const res = await Ajax.post('auth/signup', data);

		if (res && res.response) {
			const response = res.response;

			if (response.account_id) {
				ga_setAccount(response.account_id);
			}
		}

		return res;
	}

	async logout() {
		await Ajax.get('auth/logout');

		this._currentUser = { is_logged_in: false, is_impersonate: false };

		await this.emit('change', [this._currentUser.is_logged_in]);
		await this.emit('refresh', [this._currentUser.is_logged_in]);
	}

	async stopImpersonation() {
		await Ajax.get('auth/logout');
		await this.loadCurrentUser();

		const fromPageImpersonate = localStorageService.get('from-page-impersonate');

		if (fromPageImpersonate) {
			localStorageService.remove('from-page-impersonate');

			window.router.replace(fromPageImpersonate);

			this.emit('change', [this._currentUser.is_logged_in]);
		} else {
			this.emit('refresh', [this._currentUser.is_logged_in]);
		}
	}

	async forgot(user_email, recaptcha = '', uiSource) {
		const resp = await Ajax.post('auth/forgot', { user_email, 'g-recaptcha-response': recaptcha, uiSource });

		return resp.response ? resp.response : resp.error;
	}

	async reset(uid, h, uiSource) {
		return await Ajax.get(`auth/reset?uid=${uid}&h=${h}&ui_source=${uiSource}`);
	}

	updateBalance(balance) {
		if (
			this._currentUser.is_logged_in &&
			this._currentUser.account?.balance_current &&
			this._currentUser.account?.balance_current !== balance
		) {
			this._currentUser.account.balance_current = balance;
			this.emit('change_balance', [true]);
		}
	}

	isResourceAllowed(resource_key) {
		if (!this._currentUser || !this._currentUser.user) {
			return false;
		}

		const user = this._currentUser.user;

		if (!user.rules || user.role_type !== 'adm') {
			return true;
		}

		const { rules, defaultRule, resources } = user.rules;

		function checkRule(key) {
			let result = rules[key];

			if (typeof result === 'undefined') {
				result = defaultRule;
			}

			if (result === false) {
				return false;
			}

			if (resources[key].parent_key) {
				// Check parent
				return checkRule(resources[key].parent_key);
			}

			return true;
		}

		return checkRule(resource_key);
	}
}

export default new UserService();
