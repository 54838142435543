import VueClipboard from 'vue-clipboard2';
import { VueToastr } from 'vue-toastr';
import FloatingVue from 'floating-vue';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import VueGtm from '@gtm-support/vue-gtm';
import type { FloatingVueConfig } from 'floating-vue/dist/config';
import type { Router } from 'vue-router';
import type { App, Plugin } from 'vue';

export const setupPlugin: Plugin = {
	install: (app: App, options: { router: Router }) => {
		app.use(options.router)
			.use(VueClipboard)
			.use(VueToastr, {})
			.use(FloatingVue, {
				theme: {
					tooltip: {
						delay: { show: 300, hide: 100 },
					},
				},
			} as FloatingVueConfig)
			.use(createMetaManager())
			.use(metaPlugin); // optional, only needed for OptionsAPI

		// Gtm setup
		const hideMetrics = process.env.VUE_APP_HIDE_METRICS || (localStorage && Boolean(localStorage.disable_gtm));
		const GtmId = process.env.VUE_APP_GTM_ID;

		if (!hideMetrics) {
			app.use(VueGtm, {
				id: GtmId || 'GTM-MKZF7QJ', // pushok.me
				enabled: true,
				debug: ['test', 'development'].indexOf(process.env.VUE_CLI_MODE || process.env.NODE_ENV || '') !== -1,
				loadScript: true,
				vueRouter: options.router,
			});
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			app.config.globalProperties.$gtm = {
				trackEvent: function (...arg) {
					// eslint-disable-next-line no-console
					console.debug('trackEvent', ...arg);
				},
			};
		}

		return app;
	},
};
